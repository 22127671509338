
import { Vue, Options, prop } from "vue-class-component";
import { PodDexController } from "../../controllers/PodDexController";
import { ReasonListDex } from "@/data/payload/api/PodDexRequest";
import { OptionsClass } from "@/domain/entities/MainApp";
import { ReasonData } from "@/domain/entities/PodDex";
import Checkbox from "primevue/checkbox";

class Props {
  modelValue = prop<any>({});
}

@Options({
  emits: ["update:modelValue", "change"],
  components: {
    Checkbox
  }
})
export default class SelectCity extends Vue.with(Props) {
  mounted(): void {
    this.fetchData();
  }
  // reason
  async fetchData() {
    await PodDexController.getReasonList(
      new ReasonListDex({
        statusCode: "DEX",
        reasonStatus: "active"
      })
    );
  }
  get reasonData(): OptionsClass[] {
    return PodDexController.reasonList.map(
      (key: ReasonData) =>
        new OptionsClass({
          name: key.reasonDescription,
          value: key.reasonCode
        })
    );
  }
  reasonCode: string[] = [];
  get totalReasonValue(): string {
    if (this.reasonCode.length) {
      return `${this.reasonCode.length} Alasan DEX`;
    }
    return "";
  }
  onChange(value: boolean, reason: OptionsClass) {
    this.$emit("update:modelValue", this.reasonCode);
    this.$emit("change", value, reason);
  }
}
