import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "px-2 py-2 space-y-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_custom_dropdown = _resolveComponent("custom-dropdown")!

  return (_openBlock(), _createBlock(_component_custom_dropdown, {
    placeholder: "Pilih alasan DEX",
    value: _ctx.totalReasonValue
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.reasonData, (reason, index) => {
          return (_openBlock(), _createBlock("div", {
            key: index,
            class: "p-field-checkbox mt-1 flex flex-row"
          }, [
            _createVNode(_component_Checkbox, {
              name: "reason-dex",
              id: index,
              value: reason.value,
              modelValue: _ctx.reasonCode,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.reasonCode = $event)),
              onChange: val => _ctx.onChange(val, reason)
            }, null, 8, ["id", "value", "modelValue", "onChange"]),
            _createVNode("label", {
              for: String(index),
              class: "ml-2.5"
            }, _toDisplayString(reason.value) + " - " + _toDisplayString(reason.name), 9, ["for"])
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["value"]))
}